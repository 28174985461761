import './styles/orderPageStyle';
import React from 'react';
import { OrderPageContextProvider } from 'page/order/context/orderPageContext';
import OrderPageContent from './orderPageContent';
import { useAsync } from 'react-async-hook';
import OrderPageMainLayout from './orderPageMainLayout';
import { OrderAPI } from '../../appAPI';
import { isServer } from '../../utils/gatsbyUtils';
import OrderLayoutSpinner from './components/orderLayoutSpinner';
import { AdminModeProvider } from './utils/orderPageAdminUtils';
import { PageErrorContent } from '../pageError';
import { ShSpinner } from '@shoootin/components-web';

// Wrapping here on purpose
// MainLayout has to render first otherwise useHeaderSize returns default value!
const OrderPageAdmin = () => {
  if (isServer()) {
    return <OrderLayoutSpinner />;
  }
  const adminModeAllowedAsync = useAsync(OrderAPI.adminModeAllowed, []);

  if (!adminModeAllowedAsync.result) {
    return (
      <OrderPageMainLayout>
        <ShSpinner />
      </OrderPageMainLayout>
    );
  }

  if (!adminModeAllowedAsync.result.allowed) {
    console.warn('Admin mode not allowed.');
    return (
      <OrderPageMainLayout>
        <PageErrorContent message="You are not allowed" />
      </OrderPageMainLayout>
    );
  }

  return (
    <AdminModeProvider>
      <OrderPageMainLayout>
        <OrderPageContextProvider>
          <OrderPageContent />
        </OrderPageContextProvider>
      </OrderPageMainLayout>
    </AdminModeProvider>
  );
};

export default OrderPageAdmin;
